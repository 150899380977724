<template>
  <b-container fluid>
    <b-row>
        <b-col lg="12" sm="12" class="m-0" style="padding-left: 0px;padding-right: 0px;padding-bottom: 0px;">
          <div class="A4_design_seven mb-1"  style="position: relative;">
            <div class="p-3 bnfertilizer" v-if="$i18n.locale === 'bn'">
              <div style="margin-top:5px">
                <b-row>
                  <b-col lg="3" sm="3" class="text-right">
                      <div>
                          <b-img v-if="right_logo" width='80px' :src="'data:image/jpeg;base64,' + right_logo" fluid alt="Left Logo"></b-img>
                      </div>
                  </b-col>
                  <b-col lg="6" sm="6" class="text-center">
                      <div class="text-center heading" style="font-size: 25px;">
                        {{ $t('li_step.badc') }}
                      </div>
                      <div>{{ '(' + ($i18n.locale === 'bn' ? 'বিএডিসি' : 'BADC') + ')' }}
                      </div>
                      <div>
                          <h6>{{ $t('li_step.fertilizer_dis_dpt') }}</h6>
                      </div>
                      <div style="font-weight: bold;">{{ $t('li_step.fertilizer_dealer_certificate') }}
                      </div>
                  </b-col>
                  <b-col lg="3" sm="3" class="text-left">
                      <div>
                          <b-img v-if="left_logo" width='80px' :src="'data:image/jpeg;base64,' + left_logo" fluid alt="Left Logo"></b-img>
                      </div>
                  </b-col>
                </b-row>
              </div>
              <table border="0" class="mt-1"  style="width:100%;margin-top: 25px">
                <tbody>
                  <tr>
                    <td style="width: 23%" class="text-left" >{{ $t('li_step.fertilizer_dear_license_no') }}: </td>
                    <td style="width: 27%" class="text-left p-0 font-weight-bold">{{ applicantData.generate_id }} </td>
                    <td style="width: 35%" class="text-right" >{{ $t('li_step.issue_date') }}: </td>
                    <td style="width: 15%" class="text-right font-weight-bold ">{{ applicantData.issue_date | dateFormat }}</td>
                  </tr>
                </tbody>
              </table>
              <div class="text-center" style="margin-top:10px">
                <b-row>
                  <b-col>
                      <div>
                        {{ $t('li_step.certificate_comfirmation') }}
                      </div>
                  </b-col>
                </b-row>
              </div>
              <div style="float:left;width: 80%">
                <table border="0" class="mt-3"  style="width:100%">
                  <tbody>
                    <tr>
                      <td style="width: 20%"  class="text-left" >{{ $t('li_step.messrs_badc') }}</td>
                      <td style="width: 5%" class="text-left p-0">:</td>
                      <td style="width: 75%" class="text-left p-0">{{ ($i18n.locale === 'bn') ? applicantData.applicant__2174 : applicantData.applicant__1875 }}</td>
                    </tr>
                    <tr>
                      <td style="width: 20%"  class="text-left" >{{ $t('li_step.name_of_proprietor') }}</td>
                      <td style="width: 5%" class="text-left p-0">:</td>
                      <td style="width: 75%" class="text-left p-0 ">{{ ($i18n.locale === 'bn') ? applicantData.owner_name_4972 : applicantData.owner_name_4139 }}</td>
                    </tr>
                    <tr>
                      <td style="width: 20%"  class="text-left" >{{ $t('li_step.father_husband') }}</td>
                      <td style="width: 5%" class="text-left p-0">:</td>
                      <td style="width: 75%" class="text-left p-0"> {{ ($i18n.locale === 'bn') ? applicantData.father__sp_3438 : applicantData.father__sp_4683 }}</td>
                    </tr>
                    <tr>
                      <td style="width: 20%"  class="text-left" >{{ $t('li_step.mother') }}</td>
                      <td style="width: 5%" class="text-left">:</td>
                      <td style="width: 75%" class="text-left p-0">{{ ($i18n.locale === 'bn') ? applicantData.mother_nam_4416 : applicantData.mother_nam_3314 }} </td>
                    </tr>
                    <tr>
                      <td style="width: 20%" class="text-left" >{{ $t('li_step.ins_address') }}</td>
                      <td style="width: 5%" class="text-left">:</td>
                      <td style="width: 75%" class="text-left">{{ ($i18n.locale === 'bn') ? applicantData.address_ba_1907 : applicantData.address_en_6494 }}</td>
                    </tr>
                    <!-- <tr>
                      <td style="width: 20%" class="text-left" >{{ $t('globalTrans.union') }}</td>
                      <td style="width: 5%" class="text-left p-0">:</td>
                      <td style="width: 75%" class="text-left">
                        <slot v-if="isAdmin">
                          {{ getColumnName($store.state.commonObj.unionList, applicantData.union_7858) }}
                        </slot>
                        <slot v-else>
                          {{ getColumnName($store.state.ExternalUserIrrigation.commonObj.unionList, applicantData.union_7858) }}
                        </slot>
                      </td>
                    </tr> -->
                    <tr>
                      <td style="width: 20%" class="text-left" >{{ $t('globalTrans.upazila') }}</td>
                      <td style="width: 5%" class="text-left p-0">:</td>
                      <td style="width: 75%" class="text-left">
                        <slot v-if="isAdmin">
                          {{ getColumnName($store.state.commonObj.upazilaList, applicantData.upazilla) }}
                        </slot>
                        <slot v-else>
                          {{ getColumnName($store.state.ExternalUserIrrigation.commonObj.upazilaList, applicantData.upazilla) }}
                        </slot>
                      </td>
                    </tr>
                    <tr>
                      <td style="width: 20%" class="text-left" >{{ $t('globalTrans.district') }} </td>
                      <td style="width: 5%" class="text-left p-0">:</td>
                      <td style="width: 75%" class="text-left">
                        <slot v-if="isAdmin">
                          {{ getColumnName($store.state.commonObj.districtList, applicantData.district) }}
                        </slot>
                        <slot v-else>
                          {{ getColumnName($store.state.ExternalUserIrrigation.commonObj.districtList, applicantData.district) }}
                        </slot>
                      </td>
                    </tr>
                    <tr>
                      <td style="width: 20%" class="text-left" >{{ $t('globalTrans.division') }} </td>
                      <td style="width: 5%" class="text-left p-0">:</td>
                      <td style="width: 75%" class="text-left">
                        <slot v-if="isAdmin">
                          {{ getColumnName($store.state.commonObj.divisionList, applicantData.division) }}
                        </slot>
                        <slot v-else>
                          {{ getColumnName($store.state.ExternalUserIrrigation.commonObj.divisionList, applicantData.division) }}
                        </slot>
                      </td>
                    </tr>
                    <tr>
                      <td style="width: 20%" class="text-left" >{{ $t('li_step.mobile_no') }} </td>
                      <td style="width: 5%" class="text-left p-0">:</td>
                      <td style="width: 75%" class="text-left">
                        {{ applicantData.mobile_no_6683 | mobileNumber }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div style="float:left;width: 20%;padding-top: 5px">
                <b-img v-if="applicantData.picture_8654" width='150px' :src="applicantData.picture_8654" fluid alt="Picture"></b-img>
              </div>
              <table border="0" style="width:100%;margin-top: 30px !important;" class="mt-5">
                <tbody>
                  <tr>
                    <td style="width: 50%;" class="text-justify">{{ $t('li_step.badc_certificate_para_2') }}</td>
                  </tr>
                </tbody>
              </table>
              <br/>
              <b-row class="mt-5">
                <b-col lg="8" md="8" class="text-left">
                  <table border="0" class="mt-1"  style="width:100%">
                    <tbody>
                      <tr>
                        <td style="width: 4%" class="text-left" >{{ $t('li_step.exp_date') }}: </td>
                        <td style="width: 15%" class="text-left font-weight-bold"> {{ applicantData.expire_date | dateFormat }}</td>
                      </tr>
                    </tbody>
                  </table>
                </b-col>
                <b-col lg="4" md="4" class="text-center">
                  <table class="unc-ldd">
                    <!-- <tr>
                      <td>{{ $t('li_step.deputy_director') }} </td>
                    </tr> -->
                    <tr>
                      <td>
                        <slot v-if="isAdmin">
                          {{ getColumnName($store.state.commonObj.officeList, applicantData.office_id) }}
                        </slot>
                        <slot v-else>
                          {{ getColumnName($store.state.ExternalUserIrrigation.commonObj.officeList, applicantData.office_id) }}
                        </slot>
                        <br/>
                        {{ $t('li_step.badc') }}
                      </td>
                    </tr>
                  </table>
                </b-col>
              </b-row>
              <div class="footer-cert">
                <div style="display: none">{{ qrCode() }}</div>
                <b-col lg="12" md="12" class="text-left d-flex justify-content-center" style="margin-top: 5px">
                  <vue-qrcode v-if="qr_code" :value="qr_code" style="max-width: 100%;max-height: 100%;width: 110px;"/>
                </b-col>
                <div class="col-sm-12 col-md-12 col-lg-12 text-center" style="font-size: 12px">
                  <span style="font-weight: bold;">
                  {{ ($i18n.locale === 'bn') ? 'ইহা কম্পিউটারে স্বয়ংক্রিয়ভাবে তৈরি সার্টিফিকেট বিধায় স্বাক্ষরের প্রয়োজন নেই।' : 'his is a computer generated certificate, not required any signature.' }}
                  </span>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-12 text-right" style="font-size: 10px">
                  {{ ($i18n.locale === 'bn') ? 'মুদ্রণের তারিখ :' : 'Printed Date :' }} {{ today | dateFormat }}
                </div>
              </div>
            </div>
            <div class="p-3 engfertilizer" v-else>
              <div style="margin-top:20px">
                <b-row>
                  <b-col lg="3" sm="3" class="text-right">
                      <div>
                          <b-img v-if="left_logo" width='80px' :src="'data:image/jpeg;base64,' + left_logo" fluid alt="Left Logo"></b-img>
                      </div>
                  </b-col>
                  <b-col lg="6" sm="6" class="text-center">
                      <div class="text-center font-weight-bold" style="font-size:15px">
                        {{ $t('li_step.badc') }}
                      </div>
                      <div>{{ '(' + ($i18n.locale === 'bn' ? 'বিএডিসি' : 'BADC') + ')' }}
                      </div>
                      <div>
                          <h6>{{ $t('li_step.fertilizer_dis_dpt') }}</h6>
                      </div>
                      <div style="font-weight: bold;">{{ $t('li_step.fertilizer_dealer_certificate') }}
                      </div>
                  </b-col>
                  <b-col lg="3" sm="3" class="text-left">
                      <div>
                          <b-img v-if="right_logo" width='80px' :src="'data:image/jpeg;base64,' + right_logo" fluid alt="Left Logo"></b-img>
                      </div>
                  </b-col>
                </b-row>
              </div>
              <table border="0" class="mt-1"  style="width:100%;margin-top: 25px">
                <tbody>
                  <tr>
                    <td style="width: 27%" class="text-left" >{{ $t('li_step.fertilizer_dear_license_no') }}: </td>
                    <td style="width: 23%" class="text-left p-0 font-weight-bold">{{ applicantData.generate_id }} </td>
                    <td style="width: 35%" class="text-right" >{{ $t('li_step.issue_date') }}: </td>
                    <td style="width: 15%" class="text-right font-weight-bold ">{{ applicantData.issue_date | dateFormat }}</td>
                  </tr>
                </tbody>
              </table>
              <div class="text-center" style="margin-top:10px">
                <b-row>
                  <b-col>
                      <div>
                        {{ $t('li_step.certificate_comfirmation') }}
                      </div>
                  </b-col>
                </b-row>
              </div>
              <div style="float:left;width: 80%">
                <table border="0" class="mt-3"  style="width:100%">
                  <tbody>
                    <tr>
                      <td style="width: 20%"  class="text-left" >{{ $t('li_step.messrs_badc') }}</td>
                      <td style="width: 5%" class="text-left p-0">:</td>
                      <td style="width: 75%" class="text-left p-0">{{ ($i18n.locale === 'bn') ? applicantData.applicant__2174 : applicantData.applicant__1875 }}</td>
                    </tr>
                    <tr>
                      <td style="width: 20%"  class="text-left" >{{ $t('li_step.name_of_proprietor') }}</td>
                      <td style="width: 5%" class="text-left p-0">:</td>
                      <td style="width: 75%" class="text-left p-0 ">{{ ($i18n.locale === 'bn') ? applicantData.owner_name_4972 : applicantData.owner_name_4139 }}</td>
                    </tr>
                    <tr>
                      <td style="width: 20%"  class="text-left" >{{ $t('li_step.father_husband') }}</td>
                      <td style="width: 5%" class="text-left p-0">:</td>
                      <td style="width: 75%" class="text-left p-0"> {{ ($i18n.locale === 'bn') ? applicantData.father__sp_3438 : applicantData.father__sp_4683 }}</td>
                    </tr>
                    <tr>
                      <td style="width: 20%"  class="text-left" >{{ $t('li_step.mother') }}</td>
                      <td style="width: 5%" class="text-left">:</td>
                      <td style="width: 75%" class="text-left p-0">{{ ($i18n.locale === 'bn') ? applicantData.mother_nam_4416 : applicantData.mother_nam_3314 }} </td>
                    </tr>
                    <tr>
                      <td style="width: 20%" class="text-left" >{{ $t('li_step.ins_address') }}</td>
                      <td style="width: 5%" class="text-left">:</td>
                      <td style="width: 75%" class="text-left">{{ ($i18n.locale === 'bn') ? applicantData.address_ba_1907 : applicantData.address_en_6494 }}</td>
                    </tr>
                    <!-- <tr>
                      <td style="width: 20%" class="text-left" >{{ $t('globalTrans.union') }}</td>
                      <td style="width: 5%" class="text-left p-0">:</td>
                      <td style="width: 75%" class="text-left">
                        <slot v-if="isAdmin">
                          {{ getColumnName($store.state.commonObj.unionList, applicantData.union_7858) }}
                        </slot>
                        <slot v-else>
                          {{ getColumnName($store.state.ExternalUserIrrigation.commonObj.unionList, applicantData.union_7858) }}
                        </slot>
                      </td>
                    </tr> -->
                    <tr>
                      <td style="width: 20%" class="text-left" >{{ $t('globalTrans.upazila') }}</td>
                      <td style="width: 5%" class="text-left p-0">:</td>
                      <td style="width: 75%" class="text-left">
                        <slot v-if="isAdmin">
                          {{ getColumnName($store.state.commonObj.upazilaList, applicantData.upazilla) }}
                        </slot>
                        <slot v-else>
                          {{ getColumnName($store.state.ExternalUserIrrigation.commonObj.upazilaList, applicantData.upazilla) }}
                        </slot>
                      </td>
                    </tr>
                    <tr>
                      <td style="width: 20%" class="text-left" >{{ $t('globalTrans.district') }} </td>
                      <td style="width: 5%" class="text-left p-0">:</td>
                      <td style="width: 75%" class="text-left">
                        <slot v-if="isAdmin">
                          {{ getColumnName($store.state.commonObj.districtList, applicantData.district) }}
                        </slot>
                        <slot v-else>
                          {{ getColumnName($store.state.ExternalUserIrrigation.commonObj.districtList, applicantData.district) }}
                        </slot>
                      </td>
                    </tr>
                    <tr>
                      <td style="width: 20%" class="text-left" >{{ $t('globalTrans.division') }} </td>
                      <td style="width: 5%" class="text-left p-0">:</td>
                      <td style="width: 75%" class="text-left">
                        <slot v-if="isAdmin">
                          {{ getColumnName($store.state.commonObj.divisionList, applicantData.division) }}
                        </slot>
                        <slot v-else>
                          {{ getColumnName($store.state.ExternalUserIrrigation.commonObj.divisionList, applicantData.division) }}
                        </slot>
                      </td>
                    </tr>
                    <tr>
                      <td style="width: 20%" class="text-left" >{{ $t('li_step.mobile_no') }} </td>
                      <td style="width: 5%" class="text-left p-0">:</td>
                      <td style="width: 75%" class="text-left">
                        {{ applicantData.mobile_no_6683 | mobileNumber }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div style="float:left;width: 20%;padding-top: 5px">
                <b-img v-if="applicantData.picture_8654" width='150px' :src="applicantData.picture_8654" fluid alt="Picture"></b-img>
              </div>
              <table border="0" style="width:100%;margin-top: 30px !important;" class="mt-5">
                <tbody>
                  <tr>
                    <td style="width: 50%;" class="text-justify">{{ $t('li_step.badc_certificate_para_2') }}</td>
                  </tr>
                </tbody>
              </table>
              <br/>
              <b-row class="mt-5">
                <b-col lg="8" md="8" class="text-left">
                  <table border="0" class="mt-1"  style="width:100%">
                    <tbody>
                      <tr>
                        <td style="width: 4%" class="text-left" >{{ $t('li_step.exp_date') }}: </td>
                        <td style="width: 15%" class="text-left font-weight-bold"> {{ applicantData.expire_date | dateFormat }}</td>
                      </tr>
                    </tbody>
                  </table>
                </b-col>
                <b-col lg="4" md="4" class="text-center">
                  <table class="unc-ldd">
                    <!-- <tr>
                      <td>{{ $t('li_step.deputy_director') }} </td>
                    </tr> -->
                    <tr>
                      <td>
                        <slot v-if="isAdmin">
                          {{ getColumnName($store.state.commonObj.officeList, applicantData.office_id) }}
                        </slot>
                        <slot v-else>
                          {{ getColumnName($store.state.ExternalUserIrrigation.commonObj.officeList, applicantData.office_id) }}
                        </slot>
                        <br/>
                        {{ $t('li_step.badc') }}
                      </td>
                    </tr>
                  </table>
                </b-col>
              </b-row>
              <div class="footer-cert">
                <div style="display: none">{{ qrCode() }}</div>
                <b-col lg="12" md="12" class="text-left d-flex justify-content-center" style="margin-top: 5px">
                  <vue-qrcode v-if="qr_code" :value="qr_code" style="max-width: 100%;max-height: 100%;width: 110px;"/>
                </b-col>
                <div class="col-sm-12 col-md-12 col-lg-12 text-center" style="font-size: 12px">
                  <span style="font-weight: bold;">
                  {{ ($i18n.locale === 'bn') ? 'ইহা কম্পিউটারে স্বয়ংক্রিয়ভাবে তৈরি সার্টিফিকেট বিধায় স্বাক্ষরের প্রয়োজন নেই।' : 'his is a computer generated certificate, not required any signature.' }}
                  </span>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-12 text-right" style="font-size: 10px">
                  {{ ($i18n.locale === 'bn') ? 'মুদ্রণের তারিখ :' : 'Printed Date :' }} {{ today | dateFormat }}
                </div>
              </div>
            </div>
          </div>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import RestApi, { licenseRegistrationServiceBaseUrl } from '@/config/api_config'
import VueQrcode from 'vue-qrcode'
import { dateFormat } from '@/Utils/fliter'
const today = new Date().toISOString().substr(0, 10)

export default {
  props: ['applicantData', 'isAdmin'],
  data () {
    return {
      loading: false,
      badcNew: [],
      lrcpanBaseUrl: licenseRegistrationServiceBaseUrl,
      left_logo: null,
      right_logo: null,
      today: today,
      qr_code: ''
    }
  },
  components: {
    VueQrcode
  },
  created () {
    this.getReportHead()
  },
  methods: {
    qrCode () {
      this.qr_code = 'মেসার্স:' + this.applicantData.applicant__2174 + '; প্রোপাইটর:' + this.applicantData.owner_name_4972 + '; নিবন্ধন নং:' + this.applicantData.generate_id + '; নিবন্ধন তারিখ:' + dateFormat(this.applicantData.issue_date) + '; মেয়াদোত্তীর্ণের তারিখ:' + dateFormat(this.applicantData.expire_date)
    },
    getReportHead () {
      RestApi.getData(licenseRegistrationServiceBaseUrl, '/configuration/report-heading/badc-image').then(response => {
        this.left_logo = response.data.left_logo
        this.right_logo = response.data.right_logo
      })
    },
    getColumnName (list, groupId) {
      const obj = list.find(item => item.value === groupId)
      if (typeof obj !== 'undefined') {
        if (this.$i18n.locale === 'bn') {
          return obj.text_bn
        }
        return obj.text_en
      } else {
          return ''
      }
    },
    addMoreDataReturn (index, value) {
      let newDD = ''
      const allJsonData = JSON.parse(this.badcStaticData[0].add_more_3656)[index]
      Object.keys(allJsonData).forEach(key => {
        if (key === 'Nearest  Tubewell  Capacity (Cusec)') {
          const checkData = allJsonData['Nearest  Tubewell  Capacity (Cusec)']
          newDD = checkData
        }
      })
      return newDD
    },
    getIsFile (value) {
        if (value !== '') {
            const fileData = JSON.parse(value)
            if (fileData[0]) {
                return true
            } else {
                return false
            }
        } else {
            return false
        }
    },
    getFile (value) {
        if (value !== '') {
            const fileData = JSON.parse(value)
            if (fileData[0]) {
                return fileData[0]
            } else {
                return false
            }
        } else {
            return false
        }
    }
  }
}
</script>
<style scoped>
.engfertilizer {
  font-family: "Poppins", sans-serif !important;
  font-size: 16px;
}
.A4_none {
  width:21cm;
  margin: 20px;
}
.A4_design_seven {
  width:21cm;
  height: 1116px;
  margin: auto !important;
  background: url('../../../assets/images/badd.jpg');
  background-size: cover;
  padding: 5px 15px 15px 15px;
  /* border: 10px solid transparent;
  border-image: url('../../../../../assets/images/border.png') 30 repeat;
  border-image-width: 14px; */
}
.dotted {
  border-bottom: dotted 2px black;
}
.unc-ldd td {
  padding: 0px;
}
.A4_design_seven .custom-control-inline {
  pointer-events: none !important;
}
.A4_design_seven .bnfertilizer  {
  font-size: 18px;
  line-height: 36px;
}
.A4_design_seven .bnfertilizer .heading {
  line-height: 27px;
}
.footer-cert {
  position: absolute; bottom: 15px;
  width: 95%;
}
</style>
