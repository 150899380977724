<template>
  <b-container fluid>
    <b-row>
        <b-col lg="12" sm="12" class="m-0" style="padding-left: 0px;padding-right: 0px;padding-bottom: 0px;">
          <div class="A4_design_seven mb-1"  style="position: relative;">
            <div class="p-3 bnfertilizer" v-if="$i18n.locale === 'bn'">
              <div style="margin-top: 5px;">
                <b-row>
                  <b-col lg="3" class="text-right" style="margin-top: 10px;">
                    <b-img v-if="logo" width='100px' :src="'data:image/jpeg;base64,' + logo" fluid alt="Left Logo"></b-img>
                  </b-col>
                  <b-col lg="6" class="text-center heading" >
                    <div>
                      {{ $t('li_step.govt_name') }}
                    </div>
                    <div>
                      {{ $t('li_step.agri_dpt_name') }}
                    </div>
                    <div>
                      {{ $t('li_step.surface_wing') }}
                    </div>
                    <div>
                      {{ $t('li_step.fer_add') }}
                    </div>
                    <div>
                      {{ 'www.dae.gov.bd' }}
                    </div>
                  </b-col>
                  <b-col lg="3" class="text-left" style="margin-top: 10px;">
                    <b-img v-if="applicantData.image_73" width='100px' :src="applicantData.image_73" fluid alt="Left Logo"></b-img>
                  </b-col>
                </b-row>
              </div>
              <div class="text-center">
                <b-row>
                  <b-col>
                    <div>
                      <b>{{ $t('li_step.fertilizer_import_reg_certificate') }}</b>
                    </div>
                  </b-col>
                </b-row>
              </div>
              <table style="width:100%">
                <tbody>
                  <tr>
                    <td style="width: 30%" ></td>
                    <td style="width: 40%" >
                      <table align="center" border="1" class="mt-1"  style="width:100%">
                        <tbody>
                          <tr>
                            <td style="width: 100%"  class="text-center font-weight-bold" >{{ $t('li_step.registration_no') }}: IMP_{{ applicantData.generate_id }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                    <td style="width: 30%; padding-right: 20px" class="text-right">
                      <slot v-if="applicantData.type === 2">
                        <b>{{ ($i18n.locale === 'bn') ? 'নবায়ন' : 'Renewal' }}</b>
                      </slot>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="text-center" style="margin-top:10px">
                <b-row>
                  <b-col>
                      <div>
                        {{ '[' + $t('li_step.fertilizer_law_head') + ']' }}
                      </div>
                  </b-col>
                </b-row>
              </div>
              <table border="0" class="mt-1"  style="width:100%">
                <tbody>
                  <tr>
                    <td style="width: 15%"  class="text-left" >{{ $t('li_step.issue_date') }}: </td>
                    <td style="width: 35%" class="text-left p-0 font-weight-bold "> {{ applicantData.issue_date | dateFormat }}</td>
                    <td style="width: 35%" class="text-right" >{{ $t('li_step.exp_date') }}: </td>
                    <td style="width: 15%" class="text-right font-weight-bold "> {{ applicantData.expire_date | dateFormat }}</td>
                  </tr>
                </tbody>
              </table>
              <table border="0" class="mt-3" style="width:100%">
                <tbody>
                  <tr>
                    <td style="width: 5%"  class="" >{{ $t('li_step.messrs') }}</td>
                    <td style="width: 31%;" class="font-weight-bold dotted">
                      {{ ($i18n.locale === 'bn') ? applicantData.organizati_9606 : applicantData.organizati_2112 }}
                    </td>
                    <td style="width: 8%" class="text-center" >{{ $t('li_step.proprietor') }}</td>
                    <td style="width: 30%" class="font-weight-bold dotted">
                      <span>{{ ($i18n.locale === 'bn') ? applicantData.applicant__84 : applicantData.applicant__11 }}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
              <table border="0" class="mt-3" style="width:100%">
                <tbody>
                  <tr>
                    <td style="width: 23%"  class="">{{ $t('globalTrans.address') + ':' + $t('li_step.village_city_name') }}</td>
                    <td style="width: 77%;" class="font-weight-bold dotted">
                      <span>
                        <slot v-if="$i18n.locale === 'bn'">
                          {{ applicantData.business_a_8633 }}
                        </slot>
                        <slot v-else>
                           {{ applicantData.business_a_8032 }}
                        </slot>
                        <!-- <slot v-if="isAdmin">
                          {{ getColumnName($store.state.commonObj.upazilaList, applicantData.upazilla_4142) }}
                        </slot>
                        <slot v-else>
                          {{ getColumnName($store.state.ExternalUserIrrigation.commonObj.upazilaList, applicantData.upazilla_4142) }}
                        </slot>,
                        <slot v-if="isAdmin">
                          {{ getColumnName($store.state.commonObj.districtList, applicantData.district_8192) }}
                        </slot>
                        <slot v-else>
                          {{ getColumnName($store.state.ExternalUserIrrigation.commonObj.districtList, applicantData.district_8192) }}
                        </slot> -->
                        <!-- <slot v-if="isAdmin">
                          {{ getColumnName($store.state.commonObj.divisionList, applicantData.division_3384) }}
                        </slot>
                        <slot v-else>
                          {{ getColumnName($store.state.ExternalUserIrrigation.commonObj.divisionList, applicantData.division_3384) }}
                        </slot> -->
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
              <table border="0" class="mt-3" style="width:100%">
                <tbody>
                  <tr>
                    <td style="width: 8%"  class="" >{{ $t('li_step.fertilizer_name') }}</td>
                    <td style="width: 65%;" class="font-weight-bold dotted">
                      <span v-if="applicantData.imported_f_4767">
                        <slot v-if="isAdmin">
                          {{ getColumnName($store.state.licenseRegistration.commonObj.fertilizerName, applicantData.imported_f_4767) }}
                        </slot>
                        <slot v-else>
                          {{ getColumnName($store.state.ExternalLrcpn.lrcpnObj.fertilizerName, applicantData.imported_f_4767) }}
                        </slot>
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
              <table border="0" class="mt-3" style="width:100%">
                <tbody>
                  <tr>
                    <td style="width: 15%" class="" >{{ $t('li_step.chemical_name') }}</td>
                    <td style="width: 35%" class="font-weight-bold dotted">
                      <span v-if="applicantData.imported_f_4767">
                        <slot v-if="isAdmin">
                          {{ getColumnNameField($store.state.licenseRegistration.commonObj.fertilizerName, applicantData.imported_f_4767, 'chemical_element') }}
                        </slot>
                        <slot v-else>
                          {{ getColumnNameField($store.state.ExternalLrcpn.lrcpnObj.fertilizerName, applicantData.imported_f_4767, 'chemical_element') }}
                        </slot>
                      </span>
                    </td>
                    <td style="width: 10%" class="" >{{ $t('li_step.brand_name') }}</td>
                    <td style="width: 35%" class="font-weight-bold dotted">{{ applicantData.brand_name_4227 }}</td>
                  </tr>
                </tbody>
              </table>
              <!-- <table border="0" class="mt-3" style="width:100%">
                <tbody>
                  <tr>
                    <td style="width: 25%"  class="" >{{ $t('li_step.manufacture_country_name') }}</td>
                    <td style="width: 75%;" class="font-weight-bold dotted">
                      <span v-if="applicantData.company_na_2647">
                        <slot v-if="isAdmin">
                          {{ getColumnName($store.state.licenseRegistration.commonObj.foreignEnlishmentList, applicantData.company_na_2647) }}
                        </slot>
                        <slot v-else>
                          {{ getColumnName($store.state.ExternalLrcpn.lrcpnObj.foreignEnlishmentList, applicantData.company_na_2647) }}
                        </slot>
                      </span>
                      <span v-if="applicantData.country_na_1757">
                        <slot v-if="isAdmin">
                          {{ getColumnName($store.state.licenseRegistration.commonObj.countryList, applicantData.country_na_1757) }}
                        </slot>
                        <slot v-else>
                          {{ getColumnName($store.state.ExternalLrcpn.lrcpnObj.countryList, applicantData.country_na_1757) }}
                        </slot>
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table> -->
              <table border="0" style="width:100%" class="mt-2">
                <tbody>
                  <tr>
                    <td style="width: 50%;line-height: 26px !important" class="text-justify">{{ $t('li_step.fertilizer_import_para_1') }}</td>
                  </tr>
                </tbody>
              </table>
              <div class="text-left">
                <b-row>
                  <b-col style="font-weight:bold;font-size:12px">
                        {{ $t('li_step.conditions') + ':' }}
                  </b-col>
                </b-row>
              </div>
              <table border="0" style="width:100%;font-size:14px">
                <tbody>
                  <tr>
                    <td style="width: 50%"  class="text-justify" >{{ $t('li_step.fertilizer_para_2') }}</td>
                  </tr>
                  <tr>
                    <td style="width: 50%"  class="text-justify">{{ $t('li_step.fertilizer_para_3') }}</td>
                  </tr>
                  <tr>
                    <td style="width: 50%"  class="text-justify">{{ $t('li_step.fertilizer_para_4') }}</td>
                  </tr>
                  <tr>
                    <td style="width: 50%"  class="text-justify" >{{ $t('li_step.fertilizer_para_5') }}</td>
                  </tr>
                </tbody>
              </table>
              <div class="footer-cert">
                <div style="display: none">{{ qrCode() }}</div>
                <b-col lg="12" md="12" class="text-left d-flex justify-content-center" style="margin-top: 5px">
                  <vue-qrcode v-if="qr_code" :value="qr_code" style="max-width: 100%;max-height: 100%;width: 110px;"/>
                </b-col>
                <div class="col-sm-12 col-md-12 col-lg-12 text-center" style="font-size: 12px">
                  <span style="font-weight: bold;">
                  {{ ($i18n.locale === 'bn') ? 'ইহা কম্পিউটারে স্বয়ংক্রিয়ভাবে তৈরি সার্টিফিকেট বিধায় স্বাক্ষরের প্রয়োজন নেই।' : 'his is a computer generated certificate, not required any signature.' }}
                  </span>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-12 text-right" style="font-size: 10px">
                  {{ ($i18n.locale === 'bn') ? 'মুদ্রণের তারিখ :' : 'Printed Date :' }} {{ today | dateFormat }}
                </div>
              </div>
            </div>
            <div class="p-3 engfertilizer" v-else>
              <div style="margin-top: 5px;">
                <b-row>
                  <b-col lg="3" class="text-right" style="margin-top: 10px;">
                    <b-img v-if="logo" width='100px' :src="'data:image/jpeg;base64,' + logo" fluid alt="Left Logo"></b-img>
                  </b-col>
                  <b-col lg="6" class="text-center heading" >
                    <div>
                      {{ $t('li_step.govt_name') }}
                    </div>
                    <div>
                      {{ $t('li_step.agri_dpt_name') }}
                    </div>
                    <div>
                      {{ $t('li_step.surface_wing') }}
                    </div>
                    <div>
                      {{ $t('li_step.fer_add') }}
                    </div>
                    <div>
                      {{ 'www.dae.gov.bd' }}
                    </div>
                  </b-col>
                  <b-col lg="3" class="text-left" style="margin-top: 10px;">
                    <b-img v-if="applicantData.image_73" width='100px' :src="applicantData.image_73" fluid alt="Left Logo"></b-img>
                  </b-col>
                </b-row>
              </div>
              <div class="text-center">
                <b-row>
                  <b-col>
                    <div>
                      <b>{{ $t('li_step.fertilizer_import_reg_certificate') }}</b>
                    </div>
                  </b-col>
                </b-row>
              </div>
              <table style="width:100%">
                <tbody>
                  <tr>
                    <td style="width: 30%" ></td>
                    <td style="width: 40%" >
                      <table align="center" border="1" class="mt-1"  style="width:100%">
                        <tbody>
                          <tr>
                            <td style="width: 100%"  class="text-center font-weight-bold" >{{ $t('li_step.registration_no') }}: IMP_{{ applicantData.generate_id }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                    <td style="width: 30%; padding-right: 20px" class="text-right">
                      <slot v-if="applicantData.type === 2">
                        <b>{{ ($i18n.locale === 'bn') ? 'নবায়ন' : 'Renewal' }}</b>
                      </slot>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="text-center" style="margin-top:10px">
                <b-row>
                  <b-col>
                      <div>
                        {{ '[' + $t('li_step.fertilizer_law_head') + ']' }}
                      </div>
                  </b-col>
                </b-row>
              </div>
              <table border="0" class="mt-1"  style="width:100%">
                <tbody>
                  <tr>
                    <td style="width: 15%"  class="text-left" >{{ $t('li_step.issue_date') }}: </td>
                    <td style="width: 35%" class="text-left p-0 font-weight-bold "> {{ applicantData.issue_date | dateFormat }}</td>
                    <td style="width: 35%" class="text-right" >{{ $t('li_step.exp_date') }}: </td>
                    <td style="width: 15%" class="text-right font-weight-bold "> {{ applicantData.expire_date | dateFormat }}</td>
                  </tr>
                </tbody>
              </table>
              <table border="0" class="mt-3" style="width:100%">
                <tbody>
                  <tr>
                    <td style="width: 6%"  class="" >{{ $t('li_step.messrs') }}</td>
                    <td style="width: 30%;" class="font-weight-bold dotted">
                      {{ ($i18n.locale === 'bn') ? applicantData.organizati_9606 : applicantData.organizati_2112 }}
                    </td>
                    <td style="width: 8%" class="text-center" >{{ $t('li_step.proprietor') }}</td>
                    <td style="width: 30%" class="font-weight-bold dotted">
                      <span>{{ ($i18n.locale === 'bn') ? applicantData.applicant__84 : applicantData.applicant__11 }}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
              <table border="0" class="mt-3" style="width:100%">
                <tbody>
                  <tr>
                    <td style="width: 20%"  class="">{{ $t('globalTrans.address') + ':' + $t('li_step.village_city_name') }}</td>
                    <td style="width: 80%;" class="font-weight-bold dotted">
                      <span>
                        <slot v-if="$i18n.locale === 'bn'">
                          {{ applicantData.business_a_8633 }}
                        </slot>
                        <slot v-else>
                           {{ applicantData.business_a_8032 }}
                        </slot>
                        <!-- <slot v-if="isAdmin">
                          {{ getColumnName($store.state.commonObj.upazilaList, applicantData.upazilla_4142) }}
                        </slot>
                        <slot v-else>
                          {{ getColumnName($store.state.ExternalUserIrrigation.commonObj.upazilaList, applicantData.upazilla_4142) }}
                        </slot>,
                        <slot v-if="isAdmin">
                          {{ getColumnName($store.state.commonObj.districtList, applicantData.district_8192) }}
                        </slot>
                        <slot v-else>
                          {{ getColumnName($store.state.ExternalUserIrrigation.commonObj.districtList, applicantData.district_8192) }}
                        </slot> -->
                        <!-- <slot v-if="isAdmin">
                          {{ getColumnName($store.state.commonObj.divisionList, applicantData.division_3384) }}
                        </slot>
                        <slot v-else>
                          {{ getColumnName($store.state.ExternalUserIrrigation.commonObj.divisionList, applicantData.division_3384) }}
                        </slot> -->
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
              <table border="0" class="mt-3" style="width:100%">
                <tbody>
                  <tr>
                    <td style="width: 13%"  class="" >{{ $t('li_step.fertilizer_name') }}</td>
                    <td style="width: 70%;" class="font-weight-bold dotted">
                      <span v-if="applicantData.imported_f_4767">
                        <slot v-if="isAdmin">
                          {{ getColumnName($store.state.licenseRegistration.commonObj.fertilizerName, applicantData.imported_f_4767) }}
                        </slot>
                        <slot v-else>
                          {{ getColumnName($store.state.ExternalLrcpn.lrcpnObj.fertilizerName, applicantData.imported_f_4767) }}
                        </slot>
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
              <table border="0" class="mt-3" style="width:100%">
                <tbody>
                  <tr>
                    <td style="width: 15%" class="" >{{ $t('li_step.chemical_name') }}</td>
                    <td style="width: 30%" class="font-weight-bold dotted">
                      <span v-if="applicantData.imported_f_4767">
                        <slot v-if="isAdmin">
                          {{ getColumnNameField($store.state.licenseRegistration.commonObj.fertilizerName, applicantData.imported_f_4767, 'chemical_element') }}
                        </slot>
                        <slot v-else>
                          {{ getColumnNameField($store.state.ExternalLrcpn.lrcpnObj.fertilizerName, applicantData.imported_f_4767, 'chemical_element') }}
                        </slot>
                      </span>
                    </td>
                    <td style="width: 13%" class="" >{{ $t('li_step.brand_name') }}</td>
                    <td style="width: 32%" class="font-weight-bold dotted">{{ applicantData.brand_name_4227 }}</td>
                  </tr>
                </tbody>
              </table>
              <!-- <table border="0" class="mt-3" style="width:100%">
                <tbody>
                  <tr>
                    <td style="width: 30%"  class="" >{{ $t('li_step.manufacture_country_name') }}</td>
                    <td style="width: 70%;" class="font-weight-bold dotted">
                      <span v-if="applicantData.company_na_2647">
                        <slot v-if="isAdmin">
                          {{ getColumnName($store.state.licenseRegistration.commonObj.foreignEnlishmentList, applicantData.company_na_2647) }}
                        </slot>
                        <slot v-else>
                          {{ getColumnName($store.state.ExternalLrcpn.lrcpnObj.foreignEnlishmentList, applicantData.company_na_2647) }}
                        </slot>
                      </span>
                      <span v-if="applicantData.country_na_1757">
                        <slot v-if="isAdmin">
                          {{ getColumnName($store.state.licenseRegistration.commonObj.countryList, applicantData.country_na_1757) }}
                        </slot>
                        <slot v-else>
                          {{ getColumnName($store.state.ExternalLrcpn.lrcpnObj.countryList, applicantData.country_na_1757) }}
                        </slot>
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table> -->
              <table border="0" style="width:100%" class="mt-2">
                <tbody>
                  <tr>
                    <td style="width: 50%" class="text-justify">{{ $t('li_step.fertilizer_import_para_1') }}</td>
                  </tr>
                </tbody>
              </table>
              <div class="text-left">
                <b-row>
                  <b-col style="font-weight:bold;font-size:12px">
                        {{ $t('li_step.conditions') + ':' }}
                  </b-col>
                </b-row>
              </div>
              <table border="0" style="width:100%;font-size:14px">
                <tbody>
                  <tr>
                    <td style="width: 50%"  class="text-justify" >{{ $t('li_step.fertilizer_para_2') }}</td>
                  </tr>
                  <tr>
                    <td style="width: 50%"  class="text-justify">{{ $t('li_step.fertilizer_para_3') }}</td>
                  </tr>
                  <tr>
                    <td style="width: 50%"  class="text-justify">{{ $t('li_step.fertilizer_para_4') }}</td>
                  </tr>
                  <tr>
                    <td style="width: 50%"  class="text-justify" >{{ $t('li_step.fertilizer_para_5') }}</td>
                  </tr>
                </tbody>
              </table>
              <div class="footer-cert">
                <div style="display: none">{{ qrCode() }}</div>
                <b-col lg="12" md="12" class="text-left d-flex justify-content-center" style="margin-top: 5px">
                  <vue-qrcode v-if="qr_code" :value="qr_code" style="max-width: 100%;max-height: 100%;width: 110px;"/>
                </b-col>
                <div class="col-sm-12 col-md-12 col-lg-12 text-center" style="font-size: 12px">
                  <span style="font-weight: bold;">
                  {{ ($i18n.locale === 'bn') ? 'ইহা কম্পিউটারে স্বয়ংক্রিয়ভাবে তৈরি সার্টিফিকেট বিধায় স্বাক্ষরের প্রয়োজন নেই।' : 'It is a computer generated certificate, not required any signature.' }}
                  </span>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-12 text-right" style="font-size: 10px">
                  {{ ($i18n.locale === 'bn') ? 'মুদ্রণের তারিখ :' : 'Printed Date :' }} {{ today | dateFormat }}
                </div>
              </div>
            </div>
          </div>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import RestApi, { licenseRegistrationServiceBaseUrl } from '@/config/api_config'
import VueQrcode from 'vue-qrcode'
import { dateFormat } from '@/Utils/fliter'
const today = new Date().toISOString().substr(0, 10)

export default {
  props: ['applicantData', 'isAdmin'],
  data () {
    return {
      loading: false,
      badcNew: [],
      lrcpanBaseUrl: licenseRegistrationServiceBaseUrl,
      logo: null,
      today: today,
      qr_code: ''
    }
  },
  components: {
    VueQrcode
  },
  created () {
    this.getReportHead()
  },
  methods: {
    qrCode () {
      // let fertilizerName = ''
      // if (this.isAdmin) {
      //   fertilizerName = this.getColumnName(this.$store.state.licenseRegistration.commonObj.fertilizerName, this.applicantData.imported_f_4767, 'bn')
      // } else {
      //   fertilizerName = this.getColumnName(this.$store.state.ExternalLrcpn.lrcpnObj.fertilizerName, this.applicantData.imported_f_4767, 'bn')
      // }
      this.qr_code = 'মেসার্স:' + this.applicantData.organizati_9606 + '; নিবন্ধন নং:' + 'IMP_' + this.applicantData.generate_id + '; নিবন্ধন তারিখ:' + dateFormat(this.applicantData.issue_date) + '; মেয়াদোত্তীর্ণের তারিখ:' + dateFormat(this.applicantData.expire_date)
    },
    getReportHead () {
      RestApi.getData(licenseRegistrationServiceBaseUrl, '/configuration/report-heading/dae-image').then(response => {
        this.logo = response.data.logo
      })
    },
    getColumnNameField (list, groupId, column) {
      const obj = list.find(item => item.value === groupId)
      if (typeof obj !== 'undefined') {
        return obj[column]
      } else {
        return ''
      }
    },
    getColumnName (list, groupId, typeIt = null) {
      const obj = list.find(item => item.value === groupId)
      if (typeof obj !== 'undefined') {
        if (typeIt) {
          return obj.text_bn
        }
        if (this.$i18n.locale === 'bn') {
          return obj.text_bn
        }
        return obj.text_en
      } else {
          return ''
      }
    },
    getColumnNameOt (list, groupId) {
      const obj = list.find(item => parseInt(item.value) === parseInt(groupId))
      if (typeof obj !== 'undefined') {
        return obj.text
      } else {
          return ''
      }
    },
    addMoreDataReturn (index, value) {
      let newDD = ''
      const allJsonData = JSON.parse(this.badcStaticData[0].add_more_3656)[index]
      Object.keys(allJsonData).forEach(key => {
        if (key === 'Nearest  Tubewell  Capacity (Cusec)') {
          const checkData = allJsonData['Nearest  Tubewell  Capacity (Cusec)']
          newDD = checkData
        }
      })
      return newDD
    },
    getIsFile (value) {
        if (value !== '') {
            const fileData = JSON.parse(value)
            if (fileData[0]) {
                return true
            } else {
                return false
            }
        } else {
            return false
        }
    },
    getFile (value) {
        if (value !== '') {
            const fileData = JSON.parse(value)
            if (fileData[0]) {
                return fileData[0]
            } else {
                return false
            }
        } else {
            return false
        }
    }
  }
}
</script>
<style scoped>
.engfertilizer {
  font-family: "Poppins", sans-serif !important;
  font-size: 14px;
}
.A4_none {
  width:21cm;
  margin: 20px;
}
.A4_design_seven {
  width:21cm;
  height: 1116px;
  margin: auto !important;
  background: url('../../../assets/images/dae-import-new.jpg');
  background-size: cover;
  padding: 5px 15px 15px 15px;
  /* border: 10px solid transparent;
  border-image: url('../../../../../assets/images/border.png') 30 repeat;
  border-image-width: 14px; */
}
.dotted {
  border-bottom: dotted 2px black;
}
.unc-ldd td {
  padding: 0px;
}
.A4_design_seven .custom-control-inline {
  pointer-events: none !important;
}
.A4_design_seven .bnfertilizer  {
  font-size: 18px;
}
.A4_design_seven .bnfertilizer .heading {
  line-height: 27px;
}
.footer-cert {
  position: absolute; bottom: 15px;
  width: 95%;
}
</style>
