<template>
  <b-container fluid>
    <b-row>
        <b-col lg="12" sm="12" class="m-0" style="padding-left: 0px;padding-right: 0px">
          <div class="A4_design_one mb-1">
            <div class="p-3">
              <div class="text-center">
                <!-- <img src="../../../assets/images/logo.png" alt="" width="100px"> -->
                <b-img v-if="logo" width='100px' :src="'data:image/jpeg;base64,' + logo" fluid alt="Left Logo"></b-img>
              </div>
              <div class="text-center">
                <b-row>
                  <b-col>
                    <h4>
                      <div class="pl-3 pt-1 uncontrolled-label" style="font-weight: bold">
                        <b-form-checkbox-group
                          id="checkbox-group-2"
                          v-model="applicantData.service_id"
                          name="flavour-2"
                          disabled
                        >
                          <b-form-checkbox value=1> <span style="font-weight: bold;color: #212529 !important"> {{ $t('li_step.controlled') }} {{ '/' }}</span></b-form-checkbox>
                          <b-form-checkbox value=15><span style="font-weight: bold;color: #212529 !important"> {{ $t('li_step.uncontrolled') }} {{ $t('li_step.crop_variety_registration_certificate') }}</span></b-form-checkbox>
                        </b-form-checkbox-group>
                      </div>
                    </h4>
                  </b-col>
                </b-row>
              </div>
              <table border="0" class="mt-1"  style="width:100%">
                <tbody>
                  <tr>
                    <td style="width: 8%"  class="text-left" >{{ $t('li_step.registration_no') }}:</td>
                    <td style="width: 12%" class="text-left dotted p-0 font-weight-bold">{{ applicantData.generate_id }}</td>
                    <td style="width: 15%" class="text-left p-0">{{ '&nbsp;' }}</td>
                    <td style="width: 10%" class="text-right" >{{ $t('globalTrans.date') }}:</td>
                    <td style="width: 1%"></td>
                    <td style="width: 10%" class="text-left dotted font-weight-bold">{{ applicantData.issue_date | dateFormat }}</td>
                  </tr>
                </tbody>
              </table>
              <table border="0" class="mt-3" style="width:100%">
                <tbody>
                  <tr>
                    <td style="width: 16%"  class="text-left" >{{ $t('li_step.crop_name') }}:</td>
                    <td style="width: 35%;" class="text-left dotted font-weight-bold">
                      <span>{{ applicantData.bangla_nam_4284 }}</span>
                    </td>
                    <td style="width: 20%" class="text-left" >{{ $t('li_step.crop_english_name') }}:</td>
                    <td style="width: 25%" class="text-left dotted font-weight-bold">{{ applicantData.english_na_4049 }}</td>
                  </tr>
                </tbody>
              </table>
              <table border="0" style="width:100%">
                <tbody>
                  <tr>
                    <td style="width: 8%"  class="text-left" >{{ $t('li_step.scientific_name') }}:</td>
                    <td style="width: 39%" class="text-left dotted font-weight-bold font-italic">{{ applicantData.theoretica_1626 }}</td>
                  </tr>
                </tbody>
              </table>
              <table border="0" style="width:100%">
                <tbody>
                  <tr>
                    <td style="width: 12%"  class="text-left" >{{ $t('li_step.registered_variety_name') }}:</td>
                    <td style="width: 39%" class="text-left dotted font-weight-bold">{{ ($i18n.locale === 'bn') ? applicantData.proposed_n_5046 : applicantData.breeder_na_6480 }}</td>
                  </tr>
                </tbody>
              </table>
              <table border="0" style="width:100%">
                <tbody>
                  <tr>
                    <td style="width: 8%"  class="text-left" >{{ $t('li_step.source_of_variety') }}:</td>
                    <td style="width: 39%" class="text-left dotted font-weight-bold">{{ applicantData.source_cou_3069 }}</td>
                  </tr>
                </tbody>
              </table>
              <table border="0" style="width:100%">
                <tbody>
                  <tr>
                    <td style="width: 8%"  class="text-left" >{{ $t('li_step.type_of_variety') }}:</td>
                    <td style="width: 39%" class="text-left font-weight-bold">
                      <b-form-checkbox-group
                        id="checkbox-group-2"
                        v-model="applicantData.proposed_c_8816"
                        name="flavour-2"
                        disabled
                      >
                        <b-form-checkbox value=0><span style="font-weight: bold;color: #212529 !important">{{ $t('li_step.inbrid') }}</span></b-form-checkbox>
                        <b-form-checkbox value=1><span style="font-weight: bold;color: #212529 !important">{{ $t('li_step.hybrid') }}</span></b-form-checkbox>
                        <b-form-checkbox value=2><span style="font-weight: bold;color: #212529 !important">{{ $t('li_step.other') }}</span></b-form-checkbox>
                      </b-form-checkbox-group>
                    </td>
                  </tr>
                </tbody>
              </table>
              <table border="0" style="width:100%">
                <tbody>
                  <tr>
                    <td style="width: 15%"  class="text-left" >{{ $t('li_step.recommended_area') }}:</td>
                    <td style="width: 39%" class="text-left dotted font-weight-bold">{{ applicantData.proposed_c_2910 }}</td>
                  </tr>
                </tbody>
              </table>
              <table border="0" style="width:100%" >
                <tbody v-if="applicantData.uncontrolled_moa_identifier">
                  <tr>
                    <td style="width: 10%"  class="text-left">{{ $t('li_step.identifier_characteristics') }}:</td>
                    <td style="width: 39%" class="text-left dotted font-weight-bold">({{$n(1)}}) {{ JSON.parse(applicantData.uncontrolled_moa_identifier).identifying_features_1 }}</td>
                  </tr>
                    <tr>
                    <td style="width: 10%"  class="text-left"></td>
                    <td style="width: 39%" class="text-left dotted font-weight-bold">({{$n(2)}}) {{ JSON.parse(applicantData.uncontrolled_moa_identifier).identifying_features_2 }}</td>
                  </tr>
                  <tr>
                    <td style="width: 10%"  class="text-left"></td>
                    <td style="width: 39%" class="text-left dotted font-weight-bold">({{$n(3)}}) {{ JSON.parse(applicantData.uncontrolled_moa_identifier).identifying_features_3 }}</td>
                  </tr>
                    <tr>
                    <td style="width: 10%"  class="text-left"></td>
                    <td style="width: 39%" class="text-left dotted font-weight-bold">({{$n(4)}}) {{ JSON.parse(applicantData.uncontrolled_moa_identifier).identifying_features_4 }}</td>
                  </tr>
                    <tr>
                    <td style="width: 10%"  class="text-left"></td>
                    <td style="width: 39%" class="text-left dotted font-weight-bold">({{$n(5)}}) {{ JSON.parse(applicantData.uncontrolled_moa_identifier).identifying_features_5 }}</td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td style="width: 10%"  class="text-left">{{ $t('li_step.identifier_characteristics') }}:</td>
                    <td style="width: 39%" class="text-left dotted font-weight-bold">({{$n(1)}})</td>
                  </tr>
                  <tr>
                    <td style="width: 10%"  class="text-left"></td>
                    <td style="width: 39%" class="text-left dotted font-weight-bold">({{$n(2)}})</td>
                  </tr>
                   <tr>
                    <td style="width: 10%"  class="text-left"></td>
                    <td style="width: 39%" class="text-left dotted font-weight-bold">({{$n(3)}})</td>
                  </tr>
                   <tr>
                    <td style="width: 10%"  class="text-left"></td>
                    <td style="width: 39%" class="text-left dotted font-weight-bold">({{$n(4)}})</td>
                  </tr>
                   <tr>
                    <td style="width: 10%"  class="text-left"></td>
                    <td style="width: 39%" class="text-left dotted font-weight-bold">({{$n(5)}})</td>
                  </tr>
                </tbody>
              </table>
              <table border="0" style="width:100%">
                <tbody>
                  <tr>
                    <td style="width: 22%"  class="text-left">
                      {{ $t('li_step.applicant_org_name') }}:
                    </td>
                    <td class="text-left" style="width: 39%">
                      <u class="font-weight-bold" style="border-bottom: 2px dotted black;text-decoration: none;width: 100% !important;display: block;">{{ ($i18n.locale === 'bn') ? applicantData.company_na_4774 : applicantData.company_na_3782 }}, {{ applicantData['company_address(bn)'] }}</u>
                    </td>
                  </tr>
                  <!-- <tr>
                    <td colspan="2" style="width: 100%" class="text-left dotted ">
                      <div style="float:left;width: 100%">
                        {{$t('globalTrans.address')}} :
                        <b>{{ applicantData['company_address(bn)'] }} </b>
                      </div>
                      <div style="float:left;width: 20%">
                        {{$t('org_pro_upazilla.upazilla')}} :
                        <b>
                          <slot v-if="isAdmin">
                            {{ getColumnName($store.state.commonObj.upazilaList, applicantData.district_1569) }}
                          </slot>
                          <slot v-else>
                            {{ getColumnName($store.state.ExternalUserIrrigation.commonObj.upazilaList, applicantData.district_1569) }}
                          </slot>
                        </b>
                      </div>
                      <div style="float:left;width: 20%">
                        {{$t('org_pro_district.district')}} :
                        <b>
                          <slot v-if="isAdmin">
                            {{ getColumnName($store.state.commonObj.districtList, applicantData.division_6489) }}
                          </slot>
                          <slot v-else>
                            {{ getColumnName($store.state.ExternalUserIrrigation.commonObj.districtList, applicantData.division_6489) }}
                          </slot>
                        </b>
                      </div>
                      <div style="float:left;width: 20%">
                        {{$t('org_pro_division.division')}} :
                        <b>
                          <slot v-if="isAdmin">
                            {{ getColumnName($store.state.commonObj.divisionList, applicantData.division_6489) }}
                          </slot>
                          <slot v-else>
                            {{ getColumnName($store.state.ExternalUserIrrigation.commonObj.divisionList, applicantData.division_6489) }}
                          </slot>
                        </b>
                      </div>
                    </td>
                  </tr> -->
                </tbody>
              </table>
              <table border="0" style="width:100%">
                <tbody>
                  <tr>
                    <td style="width: 18%"  class="text-left" >{{ $t('li_step.dealer_reg_no') }}:</td>
                    <td style="width: 39%" class="text-left dotted font-weight-bold">{{ applicantData.seed_deale_5854 }} ({{ applicantData.seed_deale_6320 | dateFormat }})</td>
                  </tr>
                </tbody>
              </table>
              <table border="0" style="width:100%">
                <tbody>
                  <tr>
                    <td style="width: 50%"  class="text-left " >{{ $t('li_step.p_1') }}</td>
                  </tr>
                </tbody>
              </table>
              <table border="0" style="width:100%">
                <tbody>
                  <tr>
                    <td style="width: 100%"  class="text-left" >{{ $t('li_step.p_2') }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div style="display: none">{{ qrCode() }}</div>
            <b-row>
              <b-col lg="8" md="8" class="text-left d-flex justify-content-center">
                <vue-qrcode v-if="qr_code" :value="qr_code" style="max-width: 100%;max-height: 100%;width: 170px;padding-left: 30px;margin-left: 30px;"/>
              </b-col>
              <b-col lg="4" md="4" class="text-center">
                <table class="unc-ldd">
                  <tr>
                    <td>{{ $t('li_step.in_order') }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t('li_step.secretary') }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t('li_step.and') }} </td>
                  </tr>
                  <tr>
                    <td>{{ $t('li_step.dg') }} </td>
                  </tr>
                  <tr>
                    <td>{{ $t('li_step.agri_mistry') }} </td>
                  </tr>
                </table>
             </b-col>
           </b-row>
           <br/>
           <div class="col-sm-12 col-md-12 col-lg-12 text-center">
              <span style="font-weight: bold;">
              {{ ($i18n.locale === 'bn') ? 'ইহা কম্পিউটারে স্বয়ংক্রিয়ভাবে তৈরি সার্টিফিকেট বিধায় স্বাক্ষরের প্রয়োজন নেই।' : 'It is a computer generated certificate, not required any signature.' }}
              </span>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import RestApi, { licenseRegistrationServiceBaseUrl } from '@/config/api_config'
import VueQrcode from 'vue-qrcode'
import { dateFormat } from '@/Utils/fliter'
export default {
  props: ['applicantData', 'isAdmin'],
  data () {
    return {
      loading: false,
      badcNew: [],
      lrcpanBaseUrl: licenseRegistrationServiceBaseUrl,
      logo: null,
      qr_code: ''
    }
  },
  components: {
    VueQrcode
  },
  created () {
    this.getReportHead()
  },
  methods: {
    qrCode () {
      this.qr_code = 'ফসলের নাম:' + this.applicantData.bangla_nam_4284 + '; জাতের নাম:' + this.applicantData.proposed_n_5046 + '; নিবন্ধন নং:' + this.applicantData.generate_id + '; নিবন্ধন তারিখ:' + dateFormat(this.applicantData.issue_date) + '; আবেদনকারী প্রতিষ্ঠানের নাম:' + this.applicantData.company_na_4774 + '; ডিলার নিবন্ধন নম্বর ও তারিখ:' + this.applicantData.seed_deale_5854 + '(' + dateFormat(this.applicantData.seed_deale_6320) + ')'
    },
    getReportHead () {
      RestApi.getData(licenseRegistrationServiceBaseUrl, '/configuration/report-heading/uncrop-image').then(response => {
        this.logo = response.data.logo
      })
    },
    getColumnName (list, groupId) {
      const obj = list.find(item => item.value === groupId)
      if (typeof obj !== 'undefined') {
        if (this.$i18n.locale === 'bn') {
          return obj.text_bn
        }
        return obj.text_en
      } else {
          return ''
      }
    },
    getColumnNameOt (list, groupId) {
      const obj = list.find(item => parseInt(item.value) === parseInt(groupId))
      if (typeof obj !== 'undefined') {
        return obj.text
      } else {
          return ''
      }
    },
    addMoreDataReturn (index, value) {
      let newDD = ''
      const allJsonData = JSON.parse(this.badcStaticData[0].add_more_3656)[index]
      Object.keys(allJsonData).forEach(key => {
        if (key === 'Nearest  Tubewell  Capacity (Cusec)') {
          const checkData = allJsonData['Nearest  Tubewell  Capacity (Cusec)']
          newDD = checkData
        }
      })
      return newDD
    },
    getIsFile (value) {
        if (value !== '') {
            const fileData = JSON.parse(value)
            if (fileData[0]) {
                return true
            } else {
                return false
            }
        } else {
            return false
        }
    },
    getFile (value) {
        if (value !== '') {
            const fileData = JSON.parse(value)
            if (fileData[0]) {
                return fileData[0]
            } else {
                return false
            }
        } else {
            return false
        }
    }
  }
}
</script>
<style scoped>
.A4_none {
  width:21cm;
  margin: 20px;
}
.A4_design_one {
  width:21cm;
  height: 1090px;
  margin: auto !important;
  background: url('../../../assets/images/design-one-A4.jpg');
  background-size: cover;
  padding: 45px;
  /* border: 10px solid transparent;
  border-image: url('../../../../../assets/images/border.png') 30 repeat;
  border-image-width: 14px; */
}
.dotted {
  border-bottom: dotted 2px black;
}
.unc-ldd td {
  padding: 0px;
}
.A4_design_one .custom-control-inline {
  pointer-events: none !important;
}
</style>
