import { render, staticRenderFns } from "./commonComponent.vue?vue&type=template&id=dbc34930&scoped=true&"
import script from "./commonComponent.vue?vue&type=script&lang=js&"
export * from "./commonComponent.vue?vue&type=script&lang=js&"
import style0 from "./commonComponent.vue?vue&type=style&index=0&id=dbc34930&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dbc34930",
  null
  
)

export default component.exports